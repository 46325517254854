<template>
  <div
    ref="theNavbar"
    class="the-nav-bar"
  >
    <div class="nav">
      <NuxtLink
        to="/"
        class="playstation"
      >
        <img
          src="~/assets/icons/playstation.svg"
          alt="Logo PlayStation"
          width="39"
          height="30"
        />
      </NuxtLink>
      <NuxtLink
        to="/"
        class="brand"
      >
        <img
          src="~/assets/icons/weareplaystation.svg"
          alt="Logo PlayStation"
          width="177"
          height="21"
        />
      </NuxtLink>
      <nav>
        <ButtonSimple
          label="Ajouter un contenu"
          @click="openContribution"
        />
        <span
          v-show="!(authenticated && (isMobile || isLargeMobile))"
          class="nav-item"
          @click="manageLogin"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 17.909 17"
            width="17.909"
            height="17"
          >
            <path
              d="M8.954 8.554c-8.564 0-8.926 7.621-8.929 7.7L0 17.002h17.909l-.025-.748c-.008-.079-.366-7.7-8.93-7.7Zm-7.386 7c.316-1.677 1.666-5.551 7.386-5.551s7.07 3.874 7.386 5.551Z"
            />
            <path
              d="M8.955 8.929A4.465 4.465 0 1 0 4.49 4.465a4.465 4.465 0 0 0 4.465 4.464Zm0-7.481a3.017 3.017 0 1 1-3.017 3.017 3.02 3.02 0 0 1 3.017-3.017Z"
            />
          </svg>
        </span>
        <span
          v-if="authenticated"
          class="nav-item"
          @click="openNotifications"
        >
          <div
            class="counter"
            :class="counterClass"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16.645 19"
              width="16.645"
              height="19"
            >
              <path
                d="M8.322 0a2.512 2.512 0 012.51 2.392 6.44 6.44 0 013.928 5.93v3.3a2.044 2.044 0 011.884 2.035v1.257a1.1 1.1 0 01-1.1 1.1H1.1a1.1 1.1 0 01-1.1-1.1v-1.253a2.044 2.044 0 011.884-2.035v-3.3a6.442 6.442 0 013.928-5.93A2.513 2.513 0 018.322 0zm6.752 14.446v-.785a.473.473 0 00-.472-.471 1.413 1.413 0 01-1.413-1.413V8.322a4.869 4.869 0 00-3.38-4.636l-.546-.175v-1a.942.942 0 00-1.884 0v1l-.545.175a4.871 4.871 0 00-3.38 4.636v3.455a1.413 1.413 0 01-1.413 1.413.473.473 0 00-.472.471v.785zm-9.736 1.57h1.57a1.413 1.413 0 102.826 0h1.57a2.983 2.983 0 11-5.967 0z"
              />
            </svg>
            <span
              v-if="notificationsStore.unread"
              class="count"
            >
              {{ notificationsStore.unread }}
            </span>
          </div>
        </span>
        <div
          class="nav-item"
          :class="{ active: showSearch }"
          @click="tryToShowSearch"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34.75"
            height="35"
            viewBox="0 0 34.75 35"
          >
            <path
              d="M3.333 13.261a9.928 9.928 0 1 1 9.928 9.929 9.939 9.939 0 0 1-9.928-9.929m30.928 18.894L23.622 21.517a13.374 13.374 0 1 0-2.393 2.32L31.9 34.512a1.667 1.667 0 1 0 2.357-2.357"
            />
          </svg>
        </div>

        <div
          class="toggle-menu nav-item"
          :class="{ 'is-open': menuModal.options.modelValue }"
          @click="openMenu"
        >
          <svg
            v-if="!menuModal.options.modelValue"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 37 30"
            width="37"
            height="30"
          >
            <g
              fill="none"
              stroke="#000"
              stroke-width="4"
            >
              <path d="M0 2h37M0 15h37M0 28h37" />
            </g>
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            width="15.615"
            height="15.615"
            viewBox="0 0 15.615 15.615"
          >
            <g
              stroke="#fff"
              stroke-width="0.5"
            >
              <path
                d="M2.061.624A1.016 1.016 0 0 0 .624 2.061l12.93 12.93a1.016 1.016 0 1 0 1.437-1.437Z"
              />
              <path
                d="M.624 13.554a1.016 1.016 0 0 0 1.437 1.437l12.93-12.93A1.016 1.016 0 1 0 13.554.624Z"
              />
            </g>
          </svg>
        </div>
      </nav>
    </div>
    <div
      v-show="showSearch"
      class="search-panel"
    >
      <div class="search-field">
        <Select
          ref="searchSelect"
          name="search"
          placeholder="Rechercher"
          class="select"
          :options="options"
          track-by="title"
          :multiple="false"
          deselect-label=""
          select-label=""
          :clear-on-select="false"
          :show-no-options="false"
          :show-no-results="false"
          :nullable-select="false"
          :option-height="61"
          :max-height="500"
          :internal-search="false"
          :preselect-first="false"
          searchable
          @select="trySearchData"
          @search-change="fetchOptions"
        >
          <template #option="{ option }">
            <div>
              <NuxtLink
                v-if="option.route"
                :to="option.route"
                class="custom-suggest-item"
              >
                <p>{{ capitalize(option.title) }}</p>
                <p class="type">{{ option.type }}</p>
              </NuxtLink>
              <div
                v-else
                class="all-results"
              >
                <p>{{ capitalize(option.title) }}</p>
                <p class="type">{{ option.type }}</p>
              </div>
            </div>
          </template>
        </Select>
        <div
          v-if="search && search.trim().length < 3"
          class="error-search"
        >
          La recherche doit faire au moins 3 caractères
        </div>
      </div>
      <div
        class="search-button"
        @click="searchData"
      >
        <img
          src="~/assets/icons/search.svg"
          alt="Rechercher"
        />
      </div>
      <div
        class="layer"
        @click="showSearch = false"
      />
    </div>
    <nav v-show="false">
      <NuxtLink to="/avantages">Les avantages We Are PlayStation</NuxtLink>
      <NuxtLink to="/actualites-communautes"
        >L'actualité des communautés</NuxtLink
      >
      <NuxtLink to="/communautes">Toutes les communautés</NuxtLink>
      <NuxtLink to="/avis-joueurs">Les avis des joueurs</NuxtLink>
      <NuxtLink to="/challenges">Les challenges</NuxtLink>
      <NuxtLink to="/astuces">Les astuces</NuxtLink>
      <hr />
      <template v-if="communitiesMenu?.universes?.length">
        <NuxtLink
          v-for="universe in communitiesMenu.universes"
          :key="`universe-${universe.slug}`"
          :to="`/communautes/genre/${universe.slug}`"
        >
          {{ universe.title }}
        </NuxtLink>
      </template>
      <hr />
      <NuxtLink to="/wapers">Les Wapers</NuxtLink>
      <NuxtLink to="/le-coin-des-wapers">Le coin des wapers</NuxtLink>
      <hr />
      <NuxtLink to="/actualites-playstation">L'actualité PlayStation</NuxtLink>
      <NuxtLink to="/avis-medias">Les avis des médias</NuxtLink>
      <NuxtLink to="/offres">Les offres du moment PlayStation Store</NuxtLink>
    </nav>
    <div
      class="add-content-btn"
      @click="openContribution"
    >
      <img
        src="~/assets/icons/add_content.svg"
        alt="Ajouter un contenu"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { menuModal } from '@/modals/menu'
import { useVfm } from 'vue-final-modal'
import type Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import type { Suggest } from '~/types'

const crossContentStore = useCrossContentStore()
const { communitiesMenu } = storeToRefs(crossContentStore)

const { isMobile, isLargeMobile } = useCustomMediaQuery()

const searchTopStyle = useModalHeightState()

const notificationsStore = useNotificationsStore()
const counterClass = computed(() => ({
  'is-active': !!notificationsStore.unread,
}))

const vfm = useVfm()
const { gtag } = useGtag()

const openNotifications = async () => {
  const { notificationsModal } = await import('@/modals/notifications')

  if (notificationsModal.options.modelValue) {
    return notificationsModal.close()
  }
  vfm.closeAll()
  notificationsModal.open()
  gtag('event', 'cta', {
    cta_name: 'notifications',
  })
}

const showSearch = ref(false)
const openMenu = async () => {
  if (menuModal.options.modelValue) {
    return menuModal.close()
  }
  showSearch.value = false

  await vfm.closeAll()
  await menuModal.open()

  gtag('event', 'cta', {
    cta_name: 'menu',
  })
}

const { authenticated } = useAuthenticated()
const { authenticatedTemp } = useAuthenticatedTemp()

const manageLogin = async () => {
  if (authenticatedTemp.value) {
    return navigateTo('/mon-profil')
  }

  await menuModal.close()

  showSearch.value = false

  gtag('event', 'cta', {
    cta_name: 'me_connecter',
  })

  const { loginModal } = await import('@/modals/login')
  if (loginModal.options.modelValue) {
    return loginModal.close()
  }

  loginModal.open()
}

const openContribution = async () => {
  showSearch.value = false
  if (authenticated.value) {
    const { contributionModal } = await import('@/modals/contribution')

    contributionModal.open()
  } else {
    const { checkLoginModal } = await import('@/modals/checkLogin')

    checkLoginModal.open()
  }

  gtag('event', 'cta', {
    cta_name: 'ajouter_un_contenu',
  })
}

const search = ref('')
const showError = ref(false)
const searchStore = useSearchStore()
const { suggests } = storeToRefs(useSearchStore())

const searchData = async () => {
  if (
    search.value.length < 3 ||
    (search.value.length === 3 && search.value[2] === ' ')
  ) {
    showError.value = true
    return
  }

  showError.value = false
  await searchStore.fetchSearch(search.value)

  gtag('event', 'search', {
    searchterm: search.value,
  })

  search.value = ''
  showSearch.value = false

  return navigateTo('/recherche')
}

const options = computed(() => {
  if (!search.value || search.value.length < 3) return []

  return [
    ...suggests.value,
    {
      id: search.value,
      title: search.value,
      type: 'Tous les résultats',
    },
  ]
})

const trySearchData = (suggest: Suggest) => {
  if (suggest.route) {
    search.value = ''
    showSearch.value = false
    return navigateTo(suggest.route)
  }
  return searchData()
}

const route = useRoute()
const searchSelect = ref<
  (InstanceType<typeof Multiselect> & { $el: HTMLInputElement }) | null
>(null)

const tryToShowSearch = async () => {
  showError.value = false

  if (route.path === '/recherche') {
    showSearch.value = false
    return
  }

  vfm.closeAll()
  showSearch.value = !showSearch.value
  search.value = ''

  await nextTick()
  if (!showSearch.value) return

  gtag('event', 'cta', {
    cta_name: 'search',
  })

  gtag('event', 'uaevent', {
    eventCategory: 'icon',
    eventAction: 'clic',
    eventLabel: 'recherche',
  })

  searchSelect.value?.$el?.focus()
}

const fetchOptions = async (inputValue: string) => {
  search.value = inputValue

  if (!search.value || search.value.trim().length < 3) return
  await searchStore.fetchSuggest(search.value)
}
</script>

<style lang="scss" scoped>
.add-content-btn {
  position: fixed;
  right: 0.2rem;
  bottom: 6.8rem;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.6rem;
  height: 5.6rem;
  background: $secondaryBlue;
  border-radius: 50%;

  img {
    width: 3rem;
    height: 3rem;
    margin-top: -0.6rem;
  }

  @media (min-width: 820px) {
    display: none;
  }
}

.the-nav-bar {
  position: sticky;
  top: 0;
  z-index: 99;

  .search-panel {
    position: fixed;
    top: v-bind(searchTopStyle);
    left: 0;
    z-index: 8;
    width: 100%;
    height: 100%;
    background: rgb(#000, 0.8);

    .error-search {
      padding: 1rem 0;
      color: #00439c;
    }

    .layer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .search-field {
      margin: auto;
      padding: 0 8rem;
      background-color: #fff;

      :deep(.select) {
        z-index: 10;
        border-bottom: 1px solid rgb(0 0 0 / 0.2);

        .multiselect__select {
          display: none;
          transform: none;
        }

        .multiselect__tags {
          height: 84px;
          padding: 0;
          border: unset;
          border-radius: 0;

          .multiselect__input {
            width: 100% !important;
            height: 84px !important;
            margin: 0 !important;
            padding: 0 !important;
            color: #000;
            font-size: 2rem;
            border-bottom: 0 !important;
          }

          input::placeholder {
            color: #707070 !important;
          }

          .multiselect__placeholder {
            display: flex;
            align-items: center;
            height: 85px;
            margin: 0;
            padding-top: 0;
            color: #707070 !important;
            font-size: 2rem;
            text-align: center;
          }
        }

        .multiselect__content-wrapper {
          width: 100vw;
          margin: 0 -8rem;
          border: 0 !important;
          border-radius: 0;

          .multiselect__content {
            padding: 0 9.5rem 0 8rem;
            border-radius: 0;

            .multiselect__element {
              background-color: #fff;
              border-top: 1px solid rgb(0 0 0 / 0.2);
              border-radius: 0;

              .multiselect__option.multiselect__option--highlight {
                background: #2874d5;
                outline: none;

                a,
                .all-results {
                  color: #fff;
                }
              }

              .multiselect__option {
                padding: 20px 10px;
                color: #363636;
                border-radius: 0;

                a,
                .all-results {
                  display: flex;
                  justify-content: space-between;
                  color: #363636;
                  text-align: center;
                  text-decoration: none;
                }

                .type {
                  font-size: 1rem;
                  text-transform: uppercase;
                  opacity: 0.5;
                }
              }

              a {
                display: flex;
                justify-content: space-between;
                color: #363636;
                text-align: center;
                text-decoration: none;
              }

              .type {
                font-size: 1rem;
                text-transform: uppercase;
                opacity: 0.5;
              }
            }
          }
        }
      }
    }

    .search-button {
      position: absolute;
      top: 33px;
      right: 80px;
      z-index: 51;
      cursor: pointer;

      img {
        width: 1.67rem;
        height: 1.67rem;
        object-fit: contain;
      }
    }
  }
}

.playstation {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 3rem 0 8rem;
  border-right: 1px solid #bfbfbf;

  img {
    width: 3.9rem;
    height: 3.025rem;
  }
}

.brand {
  width: 17.67rem;
  padding-left: 3rem;
}

.nav {
  position: relative;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 7rem;
  background: $white;
  box-shadow: 0 1px 0 0 #bfbfbf;

  .button {
    margin-right: 16rem;
  }

  nav {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    margin-left: 2rem;
  }

  .nav-item {
    margin-right: 4rem;
    cursor: pointer;

    &:hover,
    &.active {
      svg path,
      svg line {
        fill: $blue;
      }
    }

    &.toggle-menu {
      &.is-open {
        &:hover,
        &.active {
          svg path {
            fill: $black;
          }
        }
      }

      &:not(.is-open) {
        &:hover,
        &.active {
          svg path {
            stroke: $blue;
          }
        }
      }
    }

    &:last-child {
      margin-right: 8rem;
    }

    svg {
      width: 1.67rem;
    }
  }

  .counter {
    position: relative;

    &.is-active {
      svg path {
        fill: $blue;
      }
    }

    .count {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 1.4rem;
      height: 1.4rem;
      padding: 0 0.3rem;
      color: $white;
      font-size: 0.9rem;
      background-color: $blue;
      border-radius: 1.4rem;
      transform: translate(0.9rem, -0.5rem);
    }
  }
}

@media screen and (max-width: 1600px) {
  .nav {
    .button {
      margin-right: 4rem;
    }
  }
}

@media screen and (max-width: 819px) {
  .playstation {
    padding: 0 2rem;

    img {
      width: 3rem;
      height: 2.325rem;
    }
  }

  .brand {
    width: 14.2rem;
    padding-left: 2rem;

    img {
      width: 100%;
    }
  }

  .nav {
    .button {
      display: none !important;
    }

    .nav-item {
      margin-right: 2.5rem;

      &:last-child {
        margin-right: 2rem;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .the-nav-bar {
    .search-panel {
      .search-field {
        padding: 0 2.5rem;

        :deep(.select) {
          .multiselect__content-wrapper {
            margin: 0 0 0 -2.5rem;

            .multiselect__content {
              padding: 0 2.5rem;
            }
          }
        }
      }

      .search-button {
        right: 2.5rem;
      }
    }
  }
}
</style>
